import React, { useEffect } from "react";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import Link from "../utils/link";

type Props = {
  title: string;
  headerContent: any;
};

const Header: React.FC<Props> = ({ title, headerContent }) => {
  useEffect(() => {
    if (window) {
      (window as any).initialize?.();
    }
  }, []);

  return (
    <header>
      <div className="container-xxl d-flex space-between">
        <div className="header-line"></div>
        <i className="material-icons menu pointer">menu</i>
        <Link to="/" className="tr-logo">
          <img
            loading="lazy"
            src="/uploads/logo_light.svg"
            alt="Mens in de zorg logo"
          ></img>
        </Link>
        <div className="header-links href-links">
          {/*<Link to="/event/">Event</Link>*/}
          <Link to="/">Homepagina</Link>
          <Link to="/about/">Over ons</Link>
          <Link partiallyActive={true} to="/podcasts/">
            Podcasts
          </Link>
          <Link to="/contact/">Contact</Link>
        </div>
        <div className="socials head-socials">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/mensinde.zorg"
            aria-label="Facebook"
          >
            <FaFacebook />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/mensindezorg/"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <div className="sidenav-wrapper">
        <div className="sidenav">
          <div className="sidenav-1">
            <div className="sidenav-first">
              <h6 className="mb-0">Menu</h6>
              <i className="material-icons close pointer">close</i>
            </div>
            <span className="href-links">
              <Link to="/event/">Event</Link>
              <Link to="/">Homepagina</Link>
              <Link to="/about/">Over ons</Link>
              <Link partiallyActive={true} to="/podcasts/">
                Podcasts
              </Link>
              <Link to="/contact/">Contact</Link>
            </span>
          </div>
          <div className="socials side-socials">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/mensinde.zorg"
              aria-label="Facebook"
            >
              <FaFacebook />
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/mensindezorg/"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <span className="body-overlay"></span>
      </div>
    </header>
  );
};

export default Header;
