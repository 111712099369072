/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import { Footer } from "./footer";
import { Helmet } from "react-helmet";

type Props = {
  title?: string;
  headerContent?: any;
};

const Layout: React.FC<Props> = ({ children, title, headerContent }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header title={title} headerContent={headerContent} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
