import { Link } from "gatsby";
import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="container-xl d-flex">
        <Link to="/" className="logo" aria-label="Home page">
          <img
            loading="lazy"
            src="/uploads/light-full-logo.svg"
            alt="Mens in de zorg logo"
          ></img>
        </Link>
        <div className="credentials">
          <div className="d-block">
            Solution:
            <a
              href="https://esoftware.solutions/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              eSoftware Solutions
            </a>
          </div>
          All rights reserved 2021 © Mens in de zorg
          <br />
          Photo credits: Ginelli Productions
        </div>
      </div>
    </footer>
  );
};
